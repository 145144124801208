import { common } from './common'
export const features = {
  afterConfirm: {
    AfterConfirmChangeRequestModal: {
      title: common.requestAlternative,
      cancelButton: common.cancel,
      confirmButton: common.confirm,
      messageTitle: common.messageToOrganizer,
      emailAddressTitle: common.emailAddress,
      nameTitle: common.name,
      sendChangeRequestEmail: '別の候補を依頼するメールを送信します。',
      letsSendMessageToOrganizer: '主催者にメッセージを送りましょう。'
    },
    AfterConfirmCancelModal: {
      cancelMessageTitle: common.messageToOrganizer,
      returnButton: common.back,
      cancelButton: common.cancel
    }
  },
  arrangements: {
    ArrangementTypeSection: {
      arrangeType: '調整タイプ',
      personalSectionTitle: common.personal,
      teamSectionTitle: common.team
    }
  },
  auth: {
    AuthSignUpFormStepper: {
      items: {
        '1st': 'ユーザー登録',
        '2nd': 'カレンダー登録'
      }
    },
    AuthFormSignUpBox: {
      title: common.register,
      body: {
        title: 'ユーザー登録するアカウントを\n選択してください'
      }
    },
    AuthButtons: {
      title: '{type}から選択'
    },
    AuthGuestRegistrationModalOpenButton: {
      modalTitle: '自分の予定を表示',
      canNotShowEvent: 'プレビューでは予定の表示はできません'
    },
    AuthAddCalendarAuthorizationDescription: {
      security: 'あなたの予定は調整相手には見えません',
      permission: '予定の表示には権限が必要です'
    },
    AuthGuestAddCalendarModal: {
      title: '候補と自分の予定を重ねて表示',
      introduction: {
        google: '予定を表示するために\nすべてにチェックを入れてください',
        microsoft: '予定を表示するために\nアクセスを強化してください'
      },
      buttons: {
        cancel: '登録をやめて確定する',
        confirm: '登録に進む'
      }
    },
    AuthCalendarSyncInfo: {
      caution: 'あなたの予定が第三者に閲覧されることはありません。',
      desc: 'Spirに登録するとあなたの予定をカレンダーに表示できます。',
      title: '自分の予定と候補を同時に確認しませんか？'
    },
    AuthTeamAdminAccountBox: {
      accountSituationTitle: {
        authButtons: 'Spirアカウントでログイン/新規作成',
        currentAccount: '管理者のSpirアカウント'
      },
      authButtonsDesc: {
        text: 'Spirアカウントを既にお持ちの方はログインしてください。まだお持ちでない方は新規登録からアカウントを作成してください。',
        linksText:
          '※<a class="link" href="{termLink}" target="_blank" rel="noopener noreferrer">利用規約</a>や<a class="link" href="{policyLink}" target="_blank" rel="noopener noreferrer">プライバシーポリシー</a>はこちら',
        termUrl: common.links.termsOfService,
        policyUrl: common.links.privacyPolicy
      },
      authButtons: {
        signInHere: 'ログインはこちら',
        signUpHere: '新規登録はこちら'
      },
      changeAccount: '変更する',
      changeAccountModal: {
        header: '管理者のSpirアカウントを変更しますか？',
        body: '別のSpirアカウントでログイン/新規登録する必要があります。よろしいですか？',
        cancel: common.cancel,
        changeAccount: '変更する'
      },
      mainCalendarDesc: {
        title: 'チームの予定を登録するカレンダー',
        desc: '日程調整をチームで行うにはカレンダー連携が必要です。確定した予定を登録するカレンダーを選択してください。'
      },
      mainCalendarSelect: {
        noCalendarButton: 'カレンダー選択に進む'
      }
    },
    AuthCalendarSelectMeritBox: {
      notEnoughScope: {
        title: 'カレンダー連携に必要なアクセス権の付与が不足しているため連携できませんでした',
        mainDesc:
          'カレンダー登録するためには、<span style="color: #cb3944">すべての権限にチェックを入れる必要</span>があります。',
        subDesc:
          'Spirでは予定を見つつ候補を設定したり、確定した予定を登録するために、カレンダー情報の表示・編集・アクセスが必要となります。',
        nextStepButtonTitle: '再度カレンダー選択に進む'
      }
    }
  },
  calendarLists: {
    CalendarListPanel: {
      listName: common.calendar,
      gestUser: 'ゲスト',
      createMenuTitle: '日程調整/予定を作成'
    },
    CalendarListTeams: {
      title: common.teamCalendars,
      you: common.you,
      suspendedText: common.teamSuspended
    },
    CalendarListPersonal: {
      title: '個人'
    }
  },
  confirm: {
    ConfirmEmailOtherTargetsForm: {
      addEmail: '追加',
      email: common.emailAddress,
      label: '日程確定時に追加で通知するメールアドレス',
      placeholder: 'name@example.com'
    },
    ConfirmLastActionBox: {
      cancel: 'キャンセル',
      save: '日程調整を確定'
    },
    ConfirmFillInRequestText: {
      title: '日程調整に必要な情報を入力'
    },
    ConfirmCandidateRangeInfo: {
      changeDateButton: '日程を変更'
    },
    ConfirmDurationChip: {
      hour: '打合せ時間：{hour}時間',
      minute: '打合せ時間：{minute}分'
    }
  },
  teamInvitation: {
    TeamInvitationAddCalendarButton: {
      addCalendarButtonTitle: '連携するカレンダーを追加'
    },
    TeamInvitationAuthGuestAddCalendarModal: {
      title: 'カレンダーを追加',
      confirmButton: '連携'
    }
  },
  availabilitySharing: {
    AvailabilitySharingCandidateSection: {
      bufferSetting: {
        before: '予定の前',
        after: '予定の後',
        addToCalendar: '確保時間をカレンダーに予定登録する'
      }
    },
    AvailabilitySharingFormPanel: {
      TeamQuestionnaire: {
        title: '調整相手への確認事項',
        selectedFormTitle: '使用するフォーム'
      },
      TeamParticipants: {
        attendeeAssignLogicTitle: '参加メンバー'
      }
    },
    AvailabilitySharingConfirmGuestCandidateListBox: {
      signUpButton: common.checkCandidatesOnCalendar,
      signUpAppeal: {
        description: 'ログイン・新規登録すると、Spirに連携しているあなたのカレンダーに候補を重ねて表示できます。',
        button: 'ログイン・新規登録'
      }
    },
    AvailabilitySharingSegmentSelector: {
      title: `${common.personal} / ${common.team}`
    },
    AvailabilitySharingListBox: {
      zeroItemMessage: {
        title: '空き時間URLはありません',
        description: `空き時間URLは、あなたの空き時間から候補を抽出して日程調整する機能です。<br/>詳しくは<a href="https://help.spirinc.com/ja/%E7%B9%B0%E3%82%8A%E8%BF%94%E3%81%97%E4%BD%BF%E3%81%88%E3%82%8B-%E7%A9%BA%E3%81%8D%E6%99%82%E9%96%93url-%E3%82%92%E7%99%BA%E8%A1%8C%E3%81%99%E3%82%8B" class="i18n_link" target="_blank">繰り返し使える”空き時間URL”を発行する</a>をご確認ください。`,
        meritTitle: '空き時間URLを使うメリット',
        meritList: {
          autoExtraction: '連携しているカレンダーからあなたの空き時間を自動抽出し、候補日時を探す手間が省けます。',
          receiver: 'URLを受け取った相手は、共有された候補日時から予定を確定できます。',
          repeat: '作成したURLは、繰り返し利用できます。'
        },
        createPromotion: '上の『＋ 空き時間URLを作成』ボタンから作成しましょう。',
        createPromotionMobile: '右下の『＋』ボタンから作成しましょう。'
      }
    },
    AvailabilitySharingListCardItem: {
      header: {
        moreButton: {
          publish: '公開',
          delete: common.delete,
          shareByEmail: 'メールで共有',
          duplicate: '複製',
          openExternalTagCopyModal: 'サイトに埋め込む'
        }
      },
      body: {
        attendee: {
          label: common.attendee.label,
          count: '{count}人'
        },
        duration: {
          label: common.duration
        },
        timeBuffer: {
          label: common.timeBuffer,
          display: '{timeBuffer} 分'
        }
      },
      footer: {
        links: {
          urlCopy: 'URLをコピー',
          shareByEmail: 'メールで共有',
          disabled: '公開するには設定を変更してください。',
          openExternalTagCopyModal: 'サイトに埋め込む'
        },
        editButton: '候補の確認・編集'
      }
    },
    AvailabilitySharingForm: {
      maxNumPerDayHelpToolTipMessage: '主催者のカレンダーの予定数が設定値を超える日には候補を抽出しません'
    },
    AvailabilitySharingCustomEventTitle: {
      sectionTitle: '予定のタイトル',
      formTitle: 'カレンダーに登録される予定のタイトル',
      editStartingButton: '予定のタイトルを編集',
      tooltip:
        '参加者のカレンダーに自動で登録される予定のタイトルです。空き時間URLに連携しているフォームやテキスト入力で用途にあった予定のタイトルを登録できます。',
      validationError: 'フォームの質問項目が変更されたため、設定が無効になりました。設定を再確認してください。',
      customEventTitleTemplate: {
        invitee: 'お名前',
        organizer: '主催者名',
        candidateTitle: '相手に見えるタイトル'
      },
      modal: {
        title: 'タイトルをデフォルトに戻しますか',
        description: '設定した予定のタイトルを破棄して、デフォルトの設定に変更します。本当によろしいですか',
        cancel: 'キャンセル',
        clear: 'デフォルトに戻す'
      },
      slide: {
        title: 'カレンダーに登録される予定のタイトル',
        description:
          '空き時間URLに連携しているフォームで取得している項目を使用して、お互いのカレンダーに登録される予定のタイトルのルールを変更できます。また、フォームの項目以外にも固定値としてテキスト入力することもできます。',
        reset: 'デフォルトに戻す',
        cancel: 'キャンセル',
        save: '保存'
      }
    },
    AvailabilitySharingRooms: {
      title: '会議室',
      or: 'または',
      tip: {
        message: '選択したリソースを空き時間の候補を抽出する条件として設定できます'
      },
      addButton: '会議室またはリソースを追加',
      unconnectedRooms:
        '会議室またはリソースがありません。Googleで管理しているリソースを連携するには、チームの管理者がリソースを追加する必要があります。',
      unconnectedRoomsHelpLinkText: 'ヘルプを参照する',
      unconnectedRoomsHelpLink:
        'https://help.spirinc.com/ja/%E4%BC%9A%E8%AD%B0%E5%AE%A4%E3%81%BE%E3%81%9F%E3%81%AF%E3%83%AA%E3%82%BD-%E3%82%B9%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B',
      emptyRooms: 'すべて追加済みです',
      prioritySelect: {
        priority: '優先度'
      },
      priorityOrderChangeButton: '優先度が高い順に並び替え',
      countNote: '会議室は1つの空き時間URLに5つまで設定できます',
      deletedRoom: '削除された会議室'
    },
    AvailabilitySharingTeamParticipants: {
      sectionTitle: '主催者情報'
    },
    AvailabilitySharingTeamNotifications: {
      calendarInvitationSelectForm: {
        title: '相手をカレンダーに招待',
        options: {
          inviting: {
            label: 'カレンダーに招待する',
            sub: '相手を主催者と同じカレンダーの予定に招待します。'
          },
          notInviting: {
            label: 'カレンダーに招待しない',
            sub: '相手を主催者と同じカレンダーの予定に招待しません。確定メールのみが送信されます。'
          }
        },
        warning: '確定後に予定を編集・削除した場合、調整相手に変更内容は通知されません。'
      }
    },
    AvailabilitySharingConfirmOperationBox: {
      slotSelectSectionTitle: '候補日時を選択してください'
    }
  },
  groupPolls: {
    GroupPollVoteButton: {
      confirm: common.confirm,
      noAvailableCandidate: common.noAvailableCandidate
    }
  },
  signup: {
    SignupGuide: {
      pleaseConfirmCandidate: common.pleaseConfirmCandidate
    }
  },
  unconfirmedLists: {
    UnconfirmedListStatusOperationBox: {
      label: {
        expired: '期限超過',
        requestedByConfirmer: '別候補を依頼されました',
        suggestedByConfirmer: '新候補が提案されました'
      },
      button: {
        setAlternativeCandidates: '候補を設定',
        confirmSuggestedCandidate: common.confirmSchedule,
        confirmSchedule: common.confirmSchedule
      }
    },
    UnconfirmedListEmptyMessage: {
      withPromotion: {
        title: '未確定の調整はありません',
        description:
          '候補を提案機能を使った日程調整で、調整中の予定がこちらに表示されます。<br />確定した予定は「確定済の予定」に表示されます。<br /><br />候補を提案は、調整相手と1度限り日程調整が可能なURLを発行する機能です。<br />詳しくは<a href="https://help.spirinc.com/ja/1%E5%9B%9E%E3%81%8D%E3%82%8A%E3%81%AE-%E5%80%99%E8%A3%9C%E3%82%92%E6%8F%90%E6%A1%88-%E3%81%99%E3%82%8B" class="i18n_link" target="_blank">1回きりの”候補を提案”する</a>をご確認ください。',
        meritTitle: '候補を提案を使うメリット',
        meritList: {
          calendar: 'カレンダーで予定を確認しながら好きな日時に候補を設定できます。',
          share: '設定した候補日時を調整用リンクかテキストで書き出すことができ、調整相手へ簡単に共有できます。',
          onlineMeeting: '日程が確定したら、カレンダーへの反映からWeb会議URL発行まで自動で完了します。'
        },
        createPromotion: '上の『＋ 候補を提案』ボタンまたは、カレンダーから日程調整しましょう。',
        createPromotionMobile: '右下の『＋』ボタンまたは、カレンダーから日程調整しましょう。',
        calendarPageName: 'カレンダーから日程調整'
      }
    }
  },
  changeRequested: {
    ChangeRequestedDate: {
      title: '別の候補を依頼中'
    },
    ChangeRequestedPageHeaderBox: {
      title: '別の候補を依頼しました',
      sentEmail: '主催者に別の候補を依頼するメールを配信しました。',
      sendingEmail: '主催者が別の候補を設定すると通知のメールが配信されます。',
      notice: '※改めて調整相手にメッセージをする必要はありません'
    }
  },
  schedule: {
    ScheduleConfirmOperationBox: {
      confirmCandidateBlockTitle: common.confirmSchedule
    },
    ScheduleConfirmGuestCandidateListBox: {
      confirmCandidateBlockTitle: common.confirmSchedule,
      checkCandidatesOnCalendar: common.checkCandidatesOnCalendar
    },
    ScheduleConfirmChangeRequestActionBox: {
      title: common.noAvailableConfirmationDate,
      requestAlternativeButton: common.requestAlternative,
      suggestNewSlotsButton: common.suggestNewSlots
    },
    ScheduleConfirmSuggestSidePanelBox: {
      slotsSectionTitle: common.suggestNewSlots,
      addSlotButtonTitle: common.addPossibleDates,
      newSlotsDescription: `カレンダーをドラッグ、または「${common.addPossibleDates}」ボタンから候補を追加できます。`
    }
  },
  teamSettings: {
    TeamSettingSidePanel: {
      admin: {
        plan: common.plan,
        paymentInfo: common.paymentInfo,
        teamSetting: '組織情報',
        title: '管理者設定',
        resource: '会議室またはリソース'
      },
      member: {
        canceling: '管理者によりチームプランが解約されたため、{date}以降利用できなくなります',
        canceled: 'チームは利用停止中です。再開には管理者によるプランの申し込みが必要です。'
      },
      team: {
        title: common.teamSetting,
        member: common.member,
        webhookSettings: 'Webhook設定'
      },
      personal: {
        title: '個人設定',
        myCalendar: 'チームに共有するカレンダー'
      },
      teamHelpPage: {
        title: 'Spirの使い方',
        desc: 'このページでは、Spirの使い方・活用方法をご紹介しています。',
        url: common.links.teamHelpPage
      }
    },
    TeamSettingHeader: {
      plan: 'プラン',
      trial: '{dueDate}までお試し期間中',
      paymentFailed: '決済失敗',
      trialExpired: 'お試し期間終了',
      cancel: 'チームプラン解約',
      canceling: '更新キャンセル済み',
      pastDue: '支払い期限切れ'
    },
    TeamSettingSubscriptionLaw: {
      header: common.registrationCaution.header,
      headerAttention: common.registrationCaution.headerAttention,
      freeTrial: common.registrationCaution.freeTrial,
      paymentTime:
        '支払時期：契約プランに応じて定まる期間にかかる利用料全額につき、申込、購入時にクレジット決済が実行されます。当初の申込期間経過後もご利用を継続いただく場合には、新たな利用期間の初日にクレジット決済が実行されます。クレジットカードの引き落とし日につきましては、ご利用のクレジットカードの締め日や契約内容により異なります。ご利用されるカード会社までお問い合わせください。',
      paymentMethod: common.registrationCaution.paymentMethod,
      servicePeriod:
        '商品引渡しまたはサービス提供の時期：クレジットカード利用の承認が下りた後、直ちに利用が可能です。無料プランについては、利用登録後、直ちに利用が可能です。',
      cancelRule:
        '返品・キャンセルに関する特約：本サービスについては、購入手続、更新手続き完了後の返品、キャンセルをお受けいたしません。中途解約については、利用規約に従い、いつでも行うことが可能ですが、予定されたご利用期間の途中において解約手続を行った場合でも、お支払い済みの料金は返金されません（日割計算による返金もいたしかねます）。特別な販売条件または提供条件がある商品またはサービスについては、各サービスの購入ページにおいて条件を表示します。',
      howToCancel:
        '解約方法：<a class="registration-caution-ul__link" href="{link}" target="_blank" rel="noopener noreferrer">こちら</a>をご覧ください。',
      howToCancelUrl:
        'https://help.spirinc.com/ja/%E6%9C%89%E6%96%99%E3%83%97%E3%83%A9%E3%83%B3%E3%82%92%E5%A4%89%E6%9B%B4-%E8%A7%A3%E7%B4%84%E3%81%99%E3%82%8B',
      others: common.registrationCaution.others,
      othersUrl: common.registrationCaution.othersUrl
    },
    TeamSettingPlanTableItemBox: {
      memberCountLimit: {
        lte: '<span class="member-count">{num}</span>人まで<span class="available-limit">利用可能</span>',
        gte: '<span class="member-count">{num}</span>人以上で利用の場合はご相談ください'
      },
      available: '利用可能',
      orderButton: '申し込む',
      changePlanButton: 'プランを変更する',
      currentPlanButton: '利用中のプラン',
      contactButton: '問い合わせる',
      amount: common.priceAmount,
      taxIncluded: '<span class="desc">税込価格</span>￥{amount}',
      recurringInterval: common.recurringInterval
    },
    TeamSettingPlanTableCollapse: {
      header: {
        title: 'プランの詳細',
        button: {
          expanded: '閉じる',
          shrunk: '開く'
        }
      },
      planNotes: {
        price: '※価格は税抜の表記です',
        afterTrial: '※トライアル後の利用はメンバー数に応じたプランへの申し込みが必要になります。'
      },
      table: {
        memberCountLimit: {
          lte: '人まで',
          gte: '人以上'
        },
        amount: common.priceAmount,
        recurringInterval: common.recurringInterval,
        ask: 'ご相談ください'
      }
    }
  },
  teamAvailabilitySharing: {
    TeamAvailabilitySharingExternalTagCopyModal: {
      title: 'サイトに埋め込む',
      description:
        'サイトに埋め込む場合、以下のコードを埋め込み先に貼り付けてください。<br>使い方の詳細は<a href="https://help.spirinc.com/ja/%E7%A9%BA%E3%81%8D%E6%99%82%E9%96%93url%E3%82%92%E3%83%9B%E3%83%BC%E3%83%A0%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%AB%E5%9F%8B%E3%82%81%E8%BE%BC%E3%82%80" class="link" target="_blank">こちらのヘルプページ</a>をご覧ください。',
      codeFrame: {
        title: '埋め込み用コード',
        copyButton: 'コードをコピー'
      }
    },
    TeamAvailabilitySharingListAdjustment: {
      label: '絞り込み',
      filterTitle: {
        search: '検索',
        searchInputPlaceholder: 'タイトルを検索'
      },
      member: {
        searchInputPlaceholder: 'メンバー名で検索'
      },
      duration: {
        all: 'すべてを表示'
      }
    },
    TeamAvailabilitySharingListBoxFilterConditionDisplay: {
      unit: ''
    },
    TeamAvailabilitySharingListBox: {
      zeroItemMessage: {
        title: '空き時間URLはありません',
        description: `空き時間URLは、あなたやチームメンバーの空き時間から候補を抽出して日程調整する機能です。<br/>詳しくは<a href="https://help.spirinc.com/ja/%E7%B9%B0%E3%82%8A%E8%BF%94%E3%81%97%E4%BD%BF%E3%81%88%E3%82%8B-%E7%A9%BA%E3%81%8D%E6%99%82%E9%96%93url-%E3%82%92%E7%99%BA%E8%A1%8C%E3%81%99%E3%82%8B" class="i18n_link" target="_blank">繰り返し使える”空き時間URL”を発行する</a>をご確認ください。`,
        meritTitle: '空き時間URLを使うメリット',
        meritList: {
          autoExtraction:
            '連携しているカレンダーからあなたやチームメンバーの空き時間を自動抽出し、候補日時を探す手間が省けます。',
          receiver: 'URLを受け取った相手は、共有された候補日時から予定を確定できます。',
          repeat: '作成したURLは、繰り返し利用できます。'
        },
        createPromotion: '上の『＋ 空き時間URLを作成』ボタンから作成しましょう。',
        createPromotionMobile: '右下の『＋』ボタンから作成しましょう。'
      },
      emptyMessage: {
        title: '絞り込み条件に該当する空き時間URLがありません',
        body: '指定した絞り込み条件に該当する空き時間URLがありません。<br>条件を見直して再度絞り込んでください。'
      },
      messages: {
        successes: {
          duplicated: '空き時間URLを複製しました。'
        },
        errors: {
          notMemberOfTeam: 'チームメンバーではありません。'
        }
      }
    },
    TeamAvailabilitySharingConfirm: {
      cannotFetchCandidatesByAccountTokenInvalidError: common.accountTokenInvalidError.message
    }
  },
  teamAvailabilitySharingAfterConfirms: {
    TeamAvailabilitySharingAfterConfirmCardBox: {
      header: {
        canceled: {
          description: '予定が削除され、あなたと参加者にキャンセル通知のメールを配信しました。',
          'description-mobile': '予定が削除され、\nあなたと参加者にキャンセル通知のメールを配信しました。',
          title: '予定をキャンセルしました'
        },
        normal: {
          description: 'あなたと参加者に確定メールを配信しました。',
          'description-mobile': 'あなたと参加者に確定メールを配信しました。',
          title: '{publicTitle}の日程が確定しました'
        }
      },
      confirmUrl: {
        link: "再度日程調整をする場合は<a href='{href}' target='_blank' rel='noopener noreferrer'>こちら</a>からお申し込みください。",
        'link-mobile':
          "再度日程調整をする場合は\n<a href='{href}' target='_blank' rel='noopener noreferrer'>こちら</a>からお申し込みください。"
      },
      calendarEventNotice: {
        title: 'この予定はカレンダーに追加されていません',
        description:
          '調整相手の設定により、この予定はあなたのカレンダーには自動的に追加されません。予定のダブルブッキングを防ぐためにもカレンダーへの追加を推奨しています。'
      },
      calendarEventButton: {
        google: 'Googleカレンダーに追加',
        ics: 'カレンダーアプリに追加'
      },
      descriptionContent: {
        onlineMeeting: 'Web会議URL：',
        meetingId: 'ミーティングID'
      },
      cancelLink: '日程調整完了ページ（キャンセル・再調整はこちら） :'
    }
  },
  integrationServices: {
    IntegrationServiceSlackChannelSelectForm: {
      title: 'Slackチャンネル',
      tooltip: '日程調整に関する通知を受け取るチャンネルを設定してください'
    },
    IntegrationServiceSlackChannelSelector: {
      select: {
        btn: 'Slackチャンネルを選択'
      },
      channel: {
        nameLabel: '選択したチャンネル',
        btn: '選択を解除する'
      },
      errors: {
        userCancel: 'Slackとの連携を許可してください。',
        common: 'Slackとの連携に失敗しました。'
      }
    }
  },
  weekdays: {
    WeekdayConditionForm: {
      title: '曜日・時間帯',
      weekdays: { ...common.weekdays }
    }
  },
  nav: {
    NavDrawer: {
      upgrade: '無料で便利なプランに切り替える',
      otherLinks: {
        accountSettings: 'アカウント設定',
        profile: 'プロフィール',
        signOut: 'ログアウト'
      }
    },
    navTopBar: {
      helpActions: {
        help: 'ヘルプ',
        inquiry: 'お問い合わせ'
      },
      invitationTip: '契約プランを選択してください',
      menus: {
        availabilitySharingList: common.nav.availabilityList,
        calendar: 'カレンダー',
        confirmedList: common.nav.confirmedList,
        unconfirmedList: common.nav.unconfirmedList,
        forms: 'フォーム',
        teamSettings: 'チーム設定',
        invite: 'メンバー招待',
        upgrade: '無料で便利なプランに切り替える'
      }
    },
    navBottom: {
      menu: 'メニュー',
      menus: {
        availabilitySharingList: common.nav.availabilityList,
        calendar: common.calendar,
        confirmedList: common.nav.confirmedList,
        unconfirmedList: common.nav.unconfirmedList,
        forms: 'フォーム',
        teamSettings: 'チーム設定',
        help: 'ヘルプ'
      }
    }
  },
  teamQuestionnaires: {
    TeamQuestionnairePreviewPanel: {
      title: '確認事項のプレビュー'
    },
    TeamQuestionnaireSelectSlideOutPanels: {
      ListItem: {
        selectButton: {
          selected: '選択を解除',
          default: '選択'
        }
      },
      title: 'フォームを選択',
      emptyList: {
        title: '作成済みのフォームはありません',
        description:
          '作成済みのフォームがありません。\n『フォームを新規作成』から連携するフォームを作成して空き時間URLに連携しましょう。'
      },
      footerBox: {
        cancel: 'キャンセル',
        selected: '選択を解除'
      },
      previewPanel: {
        cancelButton: 'プレビューを閉じる'
      },
      createNewFormButton: 'フォームを新規作成'
    },
    TeamQuestionnaireSelectForm: {
      UnselectedTitleBox: {
        desc: {
          unselected:
            'フォームを選択しない場合、「お名前」と「メールアドレス」は調整相手に確認されます。 | フォームを選択しない場合、「お名前」と「メールアドレス」と「主催者へのメッセージ」は調整相手に確認されます。',
          failed: 'フォーム機能を利用できません。',
          default: 'フォーム一覧データの取得に失敗しました。再ロードしてください。'
        }
      },
      DataLoaded: {
        reselectFormButton: 'フォームを再選択',
        defaultQuestions: common.questionnaires.defaultQuestions,
        footerBox: {
          selected: 'プレビューを確認',
          unselected: 'フォームを選択'
        }
      }
    },
    card: {
      TeamQuestionnaireCardQuestions: {
        QuestionTitle: {
          defaultQuestions: common.questionnaires.defaultQuestions
        }
      }
    },
    TeamQuestionnaireMoreOptionButton: {
      title: 'フォームを削除',
      deleteConfirmationModal: {
        title: 'フォームを削除しますか',
        description:
          '空き時間URLに連携している場合、連携が解除されます。また、質問項目が「カレンダーに登録される予定のタイトル」に設定されている場合、その設定が無効になる場合があります。\n本当に削除しますか。'
      }
    },
    TeamQuestionnaireCardPreviewButton: {
      title: 'プレビューを表示'
    },
    TeamQuestionnaireQuestionMoreOptionButton: {
      title: '質問を削除'
    },
    TeamQuestionnaireQuestionUiFormBox: {
      TitleForm: {
        title: '質問のタイトル',
        placeholder: 'タイトルを入力してください'
      },
      TypeSelectForm: {
        title: '質問のタイプ',
        items: {
          textInput: '一行テキスト',
          textArea: '複数行テキスト',
          phoneNumber: '電話番号',
          emailAddress: 'メールアドレス',
          checkboxes: 'チェックボックス'
        }
      },
      PlaceholderForm: {
        title: 'プレイスホルダー',
        placeholder: '回答を記入してください'
      },
      HelpTextForm: {
        title: '追加の説明文',
        placeholder: '入力の手助けになるようなテキストが必要な場合、入力してください',
        addSection: {
          buttonTitle: '質問項目に追加の説明文を追加する',
          description:
            '記入してほしい情報がプレイスホルダーのみだとわかりにくい場合に、追加の説明文を追加することができます。'
        }
      },
      RequiredForm: {
        title: '回答の必須・任意',
        switchLabel: '必須にする'
      },
      OptionsForm: {
        title: '選択肢',
        description: '回答するための選択肢を追加してください',
        placeholder: '選択肢を入力してください',
        addButton: '選択肢を追加',
        defaultOption: '選択肢',
        deleteConfirmationModal: {
          title: '選択肢を削除しますか',
          description: 'この操作は取り消せません。本当に削除しますか'
        }
      }
    },
    TeamQuestionnaireCreateButton: {
      title: 'フォームを新規作成'
    },
    TeamQuestionnaireListConditionBox: {
      unit: ''
    },
    TeamQuestionnaireChangeQuestionsOrderCard: {
      placeholderKey: 'プレイスホルダー',
      helpTextKey: 'ヘルプテキスト'
    }
  },
  questionnaireAnswers: {
    QuestionnaireAnswerExtraAttendees: {
      title: '他の参加者を招待する',
      addingFieldOpenButton: 'メールアドレスで招待',
      form: {
        cancel: 'キャンセル',
        invite: '招待'
      }
    },
    QuestionnaireAnswerFields: {
      defaultQuestions: common.questionnaires.defaultQuestions,
      checkboxesQuestionForm: {
        requiredAtLeastOne: '選択してください | 選択してください | 少なくとも１つ選択してください'
      },
      emailQuestionForm: {
        connectionLink: '連携してないメールアドレスを設定する場合はこちら',
        cancel: 'キャンセル',
        add: '確定'
      }
    }
  },
  assignLogics: {
    AssignLogicAttendee: {
      or: 'または',
      description: '複数の参加メンバー候補を作成した場合、予定が合う候補で日程を確定します。',
      orderChangeButton: '優先順位が高い順に並び替え',
      organizer: '主催者',
      group: {
        CalendarPreviewButton: {
          tooltip: 'カレンダープレビュー'
        },
        Group: {
          addButton: '同席メンバーを追加'
        },
        NewGroup: {
          addButton: '参加メンバーを追加'
        },
        GroupMenu: {
          delete: '削除'
        },
        GroupPriority: {
          priority: '優先度',
          title: '予定確定の優先順位',
          description:
            '予定の合う参加メンバー候補が複数ある場合、優先順位の順で確定します。優先順位が同じ場合は、ランダムで確定となります。'
        }
      },
      member: {
        MemberAddButton: {
          searchPlaceholder: 'メンバーを検索'
        }
      }
    }
  },
  events: {
    EventEditModal: {
      eventDeletionError: '予定の削除に失敗しました'
    }
  },
  teamEvents: {
    TeamEventMemberEditModal: {
      delete: '削除',
      edit: '編集',
      helpMessage: '確定者には通知されません',
      cancelModal: {
        title: '予定を削除してもよろしいでしょうか？',
        revertWarning: 'あなたが主催者ではない予定を削除します。この操作は取り消せません。',
        notificationWarning: '変更内容は設定しているカレンダーに反映され、予定に参加する全てのメンバーに通知されます。',
        deleteMessageLabel: '削除理由',
        close: 'キャンセル',
        cancel: '変更を確定'
      },
      availabilitySharingCancelModal: {
        title: '予定を削除してもよろしいでしょうか？',
        revertWarning: 'あなたが主催者ではない予定を削除します。この操作は取り消せません。',
        notificationWarning: '変更内容は参加メンバーのカレンダーに反映・通知されます。',
        deleteMessageLabel: '削除理由をチームに共有',
        receivers: '送信先 : 主催者へのメール、Slack通知、Webhook',
        close: 'キャンセル',
        cancel: '変更を確定',
        inviteCalendarWarning:
          '空き時間URLで「カレンダーに招待しない」設定をしている場合、調整相手は参加メンバーに含まれません。変更内容の共有は別途行ってください。'
      }
    }
  }
}
