import { render, staticRenderFns } from "./PollComfirm.vue?vue&type=template&id=3a22f875&scoped=true&"
import script from "./PollComfirm.vue?vue&type=script&lang=ts&"
export * from "./PollComfirm.vue?vue&type=script&lang=ts&"
import style0 from "./PollComfirm.vue?vue&type=style&index=0&id=3a22f875&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.0_@vue+compiler-sfc@3.2.39_babel-core@7.0.0-bridge.0_@babel+core@7.26.0__css_ux6mycwev5k63toioi3opptcxa/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a22f875",
  null
  
)

export default component.exports